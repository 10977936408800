import React, {PureComponent, ReactNode} from "react";
import widmannXs from '../../../public/assets/images/autohaus-widmann-winterholler-xs.webp';
import widmann from '../../../public/assets/images/autohaus-widmann-winterholler.webp';
import autoRothXs from '../../../public/assets/images/auto-roth-farchant-xs.webp';
import autoRoth from '../../../public/assets/images/auto-roth-farchant.webp';
import haeuslerpartnerXs from '../../../public/assets/images/autohaus-haeusler-xs.webp';
import haeuslerpartner from '../../../public/assets/images/autohaus-haeusler.webp';

export type GaragePartnersProps = {
    headline: string,
    text: string
};

export class GaragePartners extends PureComponent<GaragePartnersProps> {


    render(): ReactNode {
        const {
            headline,
            text
        } = this.props

        const RatingStars = (props: { className: string | undefined; percent: any; type: any; }) => <svg className={props.className} width="100" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 92.99 16">
            <defs>
                <linearGradient>
                    <stop offset={`${props.percent ?? 0}%`} stopColor="#F59E0B"/>
                    <stop offset={`${props.percent ?? 0}%`} stopColor="#FDE68A"/>
                </linearGradient>
            </defs>
            <path
                d="M17,6.07a.43.43,0,0,1-.12.45L13,10.24h0l.91,5.24a.44.44,0,0,1-.18.44.46.46,0,0,1-.48,0L8.5,13.47,3.74,16l-.12,0h0a.29.29,0,0,1-.16,0H3.38a.43.43,0,0,1-.3-.49L4,10.24.14,6.52A.5.5,0,0,1,.07,6a.41.41,0,0,1,.32-.2L5.71,5,8.09.23a.48.48,0,0,1,.82,0L11.29,5l5.32.76A.47.47,0,0,1,17,6.07Zm18.63-.31L30.29,5,27.91.23a.48.48,0,0,0-.82,0L24.71,5l-5.32.76a.41.41,0,0,0-.32.2.5.5,0,0,0,.07.56L23,10.24l-.91,5.24a.43.43,0,0,0,.3.49h.05a.29.29,0,0,0,.16,0h0l.12,0,4.76-2.48L32.26,16a.46.46,0,0,0,.48,0,.44.44,0,0,0,.18-.44L32,10.24h0l3.85-3.72A.43.43,0,0,0,36,6.07.47.47,0,0,0,35.61,5.76Zm19,0L49.29,5,46.91.23a.48.48,0,0,0-.82,0L43.71,5l-5.32.76a.41.41,0,0,0-.32.2.5.5,0,0,0,.07.56L42,10.24l-.91,5.24a.43.43,0,0,0,.3.49h0a.29.29,0,0,0,.16,0h0l.12,0,4.76-2.48L51.26,16a.46.46,0,0,0,.48,0,.44.44,0,0,0,.18-.44L51,10.24h0l3.85-3.72A.43.43,0,0,0,55,6.07.47.47,0,0,0,54.61,5.76Zm19,0L68.29,5,65.91.23a.48.48,0,0,0-.82,0L62.71,5l-5.32.76a.41.41,0,0,0-.32.2.5.5,0,0,0,.07.56L61,10.24l-.91,5.24a.43.43,0,0,0,.3.49h0a.29.29,0,0,0,.16,0h0l.12,0,4.76-2.48L70.26,16a.46.46,0,0,0,.48,0,.44.44,0,0,0,.18-.44L70,10.24h0l3.85-3.72A.43.43,0,0,0,74,6.07.47.47,0,0,0,73.61,5.76Zm19.25.76A.43.43,0,0,0,93,6.07a.47.47,0,0,0-.37-.31L87.29,5,84.91.23a.48.48,0,0,0-.82,0L81.71,5l-5.32.76a.41.41,0,0,0-.32.2.5.5,0,0,0,.07.56L80,10.24l-.91,5.24a.43.43,0,0,0,.3.49h.05a.29.29,0,0,0,.16,0h0l.12,0,4.76-2.48L89.26,16a.46.46,0,0,0,.48,0,.44.44,0,0,0,.18-.44L89,10.24h0Z"
                transform={`translate(-0.01)`} fill='#F59E0B' fillOpacity="1"
                fillRule="evenodd"/>
        </svg>

        return (
            <div className={`garagePartners px-6 md:px-12 pb-12 md:pb-24 grid grid-cols-12 gap-x-6`}>
                <h2 className={`text-2xl leading-8 md:text-3xl md:leading-9 font-semibold font-serif text-gray-900 col-span-full`}>{headline}</h2>
                <p className={`text-base leading-6 text-gray-500 col-span-full pb-8`}>{text}</p>
                <div className={'col-span-12 md:col-span-6 lg:col-span-4 pb-8 lg:pb-0'}>
                    <div className={'garagePartners__imageContainer aspect-[3/2] -mb-5'}>
                        <picture>
                            <source media="(max-width: 768px)" srcSet={`${widmannXs.src}`}/>
                            <source media="(min-width: 975px)" srcSet={`${widmann.src}`}/>
                            <img
                                loading={'lazy'}
                                src={widmann.src}
                                alt="Autohaus Widmann + Winterholler - Farchant"
                                width={'430'}
                                height={'290'}
                            />
                        </picture>
                        {/*<Image src={widmann}*/}
                        {/*       width={'3'}*/}
                        {/*       height={'2'}*/}
                        {/*       sizes="(max-width: 768px) 100vw,*/}
                        {/*              (max-width: 975px) 45vw,*/}
                        {/*              28vw"*/}
                        {/*       alt={'Driver Center Köln-Zollstock'}*/}
                        {/*       layout={'responsive'}/>*/}
                    </div>
                    <div className={'garagePartners__ratingContainer text-center relative'}>
                        <div className={'rounded-md mx-auto my-0 p-2 mb-4 md:mb-6 bg-white items-center inline-flex shadow-md'}>
                            <RatingStars className={'inline-block pr-2'} type={"rating"} percent={100} /><span className={'font-serif text-gray-900 inline-block font-semibold pr-2 pl-2'}>5/5</span>
                        </div>
                    </div>
                    <h3 className={'text-lg leading-8 font-semibold text-black font-serif'}>
                        Autohaus Widmann + Winterholler - Farchant
                    </h3>
                    <p className={'text-base leading-6 font-normal text-gray-500 pb-2'}>&quot;Kompetent zuvorkommend und freundlich!!!
                        Bin mit mit dem Autohaus Widmann Winterholler in Farchant absolut zufrieden, ich habe bis jetzt nur gute Erfahrungen...&quot;</p>
                    <span className={'text-base leading-6 font-semibold text-gray-900'}>Oechsler Michael</span>
                    <span className={'text-base leading-6 font-normal text-gray-500 block'}>BMW - 540i xDrive</span>
                </div>
                <div className={'col-span-12 md:col-span-6 lg:col-span-4 pb-8 lg:pb-0'}>
                    <div className={'garagePartners__imageContainer aspect-[3/2] -mb-5'}>
                        <picture>
                            <source media="(max-width: 768px)" srcSet={`${autoRothXs.src}`}/>
                            <source media="(min-width: 975px)" srcSet={`${autoRoth.src}`}/>
                            <img
                                loading={'lazy'}
                                src={autoRoth.src}
                                alt="Auto Roth – Fürth"
                                width={'430'}
                                height={'290'}
                            />
                        </picture>
                        {/*<Image src={autoRoth}*/}
                        {/*       width={'3'}*/}
                        {/*       height={'2'}*/}
                        {/*       sizes="(max-width: 768px) 100vw,*/}
                        {/*              (max-width: 975px) 45vw,*/}
                        {/*              28vw"*/}
                        {/*       alt={'Auto Roth – Fürth'}*/}
                        {/*       layout={'responsive'}/>*/}
                    </div>
                    <div className={'garagePartners__ratingContainer text-center relative'}>
                        <div className={'rounded-md mx-auto my-0 p-2 mb-4 md:mb-6 bg-white items-center inline-flex shadow-md'}>
                            <RatingStars className={'inline-block pr-2'} type={"rating"} percent={100} /><span className={'font-serif text-gray-900 inline-block font-semibold pr-2 pl-2'}>5/5</span>
                        </div>
                    </div>
                    <h3 className={'text-lg leading-8 font-semibold text-black font-serif'}>Auto Roth – Fürth</h3>
                    <p className={'text-base leading-6 font-normal text-gray-500 pb-2'}>&quot;Hervorragend! Note 1!!! Ab jetzt meine neue Stamm-Werkstatt... Bei diesem Werkstatt-Team hat man wirklich das Gefühl, dass auf den Kunden geschaut wird und diesem keine unnötigen Reparaturen...&quot;</p>
                    <span className={'text-base leading-6 font-semibold text-gray-900'}>Frithjof S.</span>
                    <span className={'text-base leading-6 font-normal text-gray-500 block'}>Skoda - Scala</span>
                </div>
                <div className={'col-span-12 lg:col-span-4 lg:inline-block md:hidden inline-block'}>
                    <div className={'garagePartners__imageContainer aspect-[3/2] -mb-5'}>
                        <picture>
                            <source media="(max-width: 768px)" srcSet={`${haeuslerpartnerXs.src}`}/>
                            <source media="(min-width: 975px)" srcSet={`${haeuslerpartner.src}`}/>
                            <img
                                loading={'lazy'}
                                src={haeuslerpartner.src}
                                alt="Häusler Automobile - München"
                                width={'430'}
                                height={'290'}
                            />
                        </picture>
                        {/*<Image src={haeuslerpartner}*/}
                        {/*       width={'3'}*/}
                        {/*       height={'2'}*/}
                        {/*       sizes="(max-width: 768px) 100vw,*/}
                        {/*              (max-width: 975px) 45vw,*/}
                        {/*              28vw"*/}
                        {/*       alt={'Häusler Automobile - München'}*/}
                        {/*       layout={'responsive'}/>*/}
                    </div>
                    <div className={'garagePartners__ratingContainer text-center relative'}>
                        <div className={'rounded-md mx-auto my-0 p-2 mb-4 md:mb-6 bg-white items-center inline-flex shadow-md'}>
                            <RatingStars className={'inline-block pr-2'} type={"rating"} percent={100} /><span className={'font-serif text-gray-900 inline-block font-semibold pr-2 pl-2'}>5/5</span>
                        </div>
                    </div>
                    <h3 className={'text-lg leading-8 font-semibold text-black font-serif'}>Häusler Automobile - München</h3>
                    <p className={'text-base leading-6 font-normal text-gray-500 pb-2'}>&quot;Fantastischer Service: Mit einem vermeintlichen Schaden am Turbolader vorgefahren. Die kompetenten Mitarbeiter haben das Problem analysiert und festgestellt, das ein Sensor einen...&quot;</p>
                    <span className={'text-base leading-6 font-semibold text-gray-900'}>Christian Williges</span>
                    <span className={'text-base leading-6 font-normal text-gray-500 block'}>Opel - Mokka</span>
                </div>
            </div>
        );
    }
}